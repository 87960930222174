<template>
  <v-footer app id="footer" class="px-0" :elevation="18">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="6" lg="4" class="px-0">
          <div class="px-4">
            <v-btn
              @click="hasAddedProduct ? updateProduct() : addProduct()"
              color="info"
              class="px-5"
              block
              depressed
              x-large
              rounded
              :disabled="hasToDisableButton"
            >
              <span id="cart-button-text" class="text-h3">
                {{
                  hasAddedProduct
                    ? "MODIFICAR"
                    : `AGREGAR A MI PEDIDO (${currencySymbol()}${formatPrice(
                        currentPrice,
                        2
                      )})`
                }}
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { formatPrice, currencySymbol, getProductPrice } from "@/utils/price.js";
import getCurrentProduct from "@/mixins/getCurrentProduct";
import shopUtils from "@/mixins/shopUtils";
export default {
  mixins: [getCurrentProduct, shopUtils],
  methods: {
    formatPrice,
    currencySymbol,
    getProductPrice,
    async addProduct() {
      if (!this.validatePickupPointSelection()) {
        return;
      }
      await this.$store
        .dispatch("deliveryCart/addProduct", {
          product: this.currentProduct,
          routeQuery: this.$route.query,
          unitPrice: this.currentProductOriginDetails.price,
          quantity: this.$store.state.currentProduct.quantity,
          options: this.$store.state.currentProduct.options,
        })
        .finally(() => {
          this.$router.push({
            name: "MenuWeborders",
            query: this.$route.query,
          });
          this.$store.dispatch("currentProduct/cleanCurrentProduct");
        });
    },
    async updateProduct() {
      if (!this.validatePickupPointSelection()) return;
      await this.$store
        .dispatch("deliveryCart/updateProduct", {
          routeParams: this.$route.params,
          quantity: this.$store.state.currentProduct.quantity,
          options: this.$store.state.currentProduct.options,
        })
        .finally(() => {
          this.$router.push({
            name: "WebordersPrecart",
            query: this.$route.query,
          });
          this.$store.dispatch("currentProduct/cleanCurrentProduct");
        });
    },
  },
  computed: {
    currentPrice() {
      let optionsTotalPrice = 0;

      if (
        this.$store.state.currentProduct.options &&
        this.$store.state.currentProduct.options[0]
      ) {
        optionsTotalPrice = this.$store.state.currentProduct.options.reduce(
          (previousValue, currentValue) =>
            previousValue +
            currentValue.quantity * Number(currentValue.unitPrice),
          0
        );
      }

      return (
        this.currentProductOriginDetails.price *
          this.$store.state.currentProduct.quantity +
        optionsTotalPrice
      );
    },
    hasToDisableButton() {
      //Este computed me devuelve true o false que despues uso para deshabilitar el boton
      var product = this.$store.state.currentProduct; //Me traigo el producto actual
      //Inicializo las variables que voy a usar para contar la cantidad de gustos y adicionales
      var currentOptionsQty = 0;
      //Me fijo por cada opcion
      if (product.options.length > 0) {
        product.options.forEach((option) => {
          if (
            option.optionsGroupsName === "Adicionales" ||
            option.optionsGroupsName === "Gustos"
          ) {
            //Si es un adicional le sumo esa cantidad a los adicionales
            currentOptionsQty += option.quantity;
          }
        });
      }
      return (
        currentOptionsQty < (this.currentProduct?.minimumFlavorLimit ?? 0) && //Si tengo que validar por adicionales y la cantidad es menor que la minima tengo que devolver true
        (this.hasAdditionalsOptions() || this.hasFlavorOptions())
      );
    },
  },
};
</script>
<style lang="sass" scoped>
#footer
  background-position: center bottom
  a
    text-decoration: none

#cart-button-text
  letter-spacing: 0.06rem !important
</style>
